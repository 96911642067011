<template>
  <b-card>
    <base-info
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      title="Info Sumbu"
    />
  </b-card>
</template>

<script>

import {BCard} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    BCard,
    BaseInfo
  },

  data() {
    return {
      dataurl:"/tire-axle",
      baseroute:"operasional-tire-axle",
      fields: [
        { key: 'name', label: 'Nama Konfigurasi', bold:true},
        { key: 'amount', label: 'Jumlah Ban', type:'number' }
      ],
    }
  },
}
</script>
