<template>
  <div>
    <b-row>
      <b-col cols="6">
        <info />
      </b-col>
      <b-col cols="6">
        <detail />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol, BCard,BTabs, BTab} from 'bootstrap-vue'

import Info from './include/Info.vue'
import Detail from './include/Detail.vue'

export default {
  components: {
    BRow,
    BCol,
    Info,
    Detail
  },
}
</script>
