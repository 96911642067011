<template>
  <b-card>
    <b-row>
      <b-col cols="10">
        <strong><h3>Daftar Posisi Ban</h3></strong>
      </b-col>
      <b-col cols="2">
        <b-button
          size="sm"
          variant="primary"
          @click="showModal"
        >
          <feather-icon icon="PlusCircleIcon" />
        </b-button>
      </b-col>
      <b-col cols="12">
        <br>
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >
          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          >

            <template #cell(_)="data">
              <span
                class="mr-1"
              >
                <b-link @click="editForm(data)">
                  <b-badge variant="primary">
                    <feather-icon icon="EditIcon" />
                  </b-badge>
                </b-link>
              </span>
              <span
                class="mr-1"
              >
                <b-link @click="deleteForm(data)">
                  <b-badge variant="warning">
                    <feather-icon icon="TrashIcon" />
                  </b-badge>
                </b-link>
              </span>
            </template>
            <template #cell(#)="data">
              {{ data.index+1 }}
            </template>
            
          </b-table>
        </b-overlay>
        <b-modal
          id="modal-center"
          ref="my-modal-form"
          centered
          title="Tambah Posisi Ban"
          hide-footer
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols-md="4"
                    label="Nama Posisi"
                    label-for="position_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Posisi"
                      rules="required"
                    >
                      <b-form-input
                        v-model="position_name"
                        :plaintext="false"
                        placeholder="Nama Sumbu Ban"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="primary"
                    type="button"
                    @click.prevent="validationForm"
                  >
                      
                    <span>Simpan</span>
                  </b-button>
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="warning"
                    type="button"
                    @click.prevent="hideModal"
                  >
                    <span>Batal</span>
                  </b-button>
                </b-col>
                <br>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BCol, BRow, BTable, BOverlay, BButton, BLink, BBadge, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BButton,
    BLink,
    BBadge
  },

  data() {
    return {
      dataUrl:"/tire-axle/"+this.$route.params.id+"/detail",
      fieldsTable: [
        { key: '#', label: '#'},
        { key: 'name', label: 'Nama Posisi'}
      ],
      items:[],
      datatable:[],
      loading:false,
      position_name:"",
      detail_id:null
    }
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      this.$http.get(this.dataUrl).then(res=>{
        this.fieldsTable.push('_')
        
        this.items = res.data.data
        this.loading = false
      })
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          if(this.detail_id){
            this.$http.put(this.dataUrl+'/'+this.detail_id,{name:this.position_name})
            .then(()=>{
              this.fetchData()
              this.hideModal()
            })
            .catch(err=>{
              this.$bvToast.toast(JSON.stringify(err), {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
            })
          }else{
            this.$http.post(this.dataUrl,{name:this.position_name})
            .then(()=>{
              this.fetchData()
              this.hideModal()
            })
            .catch(err=>{
              this.$bvToast.toast(JSON.stringify(err), {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
            })
          }
          
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    showModal(){
      this.$refs['my-modal-form'].show()
    },
    hideModal(){
      this.$refs['my-modal-form'].hide()
      this.$refs.simpleRules.reset()
      this.position_name = ""
      this.detail_id = null
    },
    editForm(data){
      this.$http.get(this.dataUrl+'/'+data.item.id).then(res=>{
        this.detail_id = data.item.id
        this.position_name = res.data.name
        this.$refs['my-modal-form'].show()
      })
    },
    deleteForm(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataUrl+'/'+id)
              .then(() => {
                this.fetchData()
  
            })
          }
        })
    }
  }
}
</script>
